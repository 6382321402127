<template>
  <div>
    <!-- <h2>地图打卡</h2> -->
    <div class="map-container" style="height: 500px">
      <el-amap :center="center" :zoom="zoom" id="container">
        <el-amap-marker :position="center"></el-amap-marker>
      </el-amap>
    </div>
    <div class="btnSI">
      <!-- <mt-button type="primary" @click="reposition" class="Repositioning"
        >重新定位</mt-button
      > -->
      <mt-button
        v-show="isShow"
        type="danger"
        @click="confirmclockin"
        class="Confirmclockin"
        >确认打卡</mt-button
      >
      <mt-button v-show="!isShow" type="default" class="Confirmclockin"
        >已打卡</mt-button
      >
    </div>
  </div>
</template>

<script>
import { KqInfo, StuKaoqing } from "utils/interface";
import { MessageBox } from "mint-ui";
import { Toast } from "mint-ui";
import { GPS } from 'utils/gps'
export default {
  name: "punch",
  data() {
    return {
      center: [116.397428, 39.90923],
      zoom: 15,
      jd: "",
      wd: "",
      address: "",
      nowDate: "",
      userInfo: {},
      isShow: true,
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getLocation();
    this.formatDate();
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.userInfo = userInfo;
    this.kqInfo();
    // let instance = Toast(
    //   "定位服务需要您开启定位权限，请确认打开了软件的定位权限和手机系统的GPS服务！"
    // );
    // setTimeout(() => {
    //   instance.close();
    // }, 4000);
  },
  methods: {
    // 获取当前位置
    getLocation() {
      const self = this;
      const map = new AMap.Map("container", {
        resizeEnable: true,
        zoom: 18,
      });
      AMap.plugin("AMap.Geolocation", function () {
        var geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 10000,
          buttonPosition: "RB", //定位按钮的停靠位置
          buttonOffset: new AMap.Pixel(10, 20), //定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
          zoomToAccuracy: false, //定位成功后是否自动调整地图视野到定位点
          useNative: true,
          panToLocation: true, //定位成功后将定位到的位置作为地图中心点，默认：true
          noIpLocate:3, //禁止使用IP定位
          noGeoLocation:0 //禁止使用浏览器定位
        });
        map.addControl(geolocation);
        geolocation.getCurrentPosition();
        AMap.event.addListener(geolocation, "complete", onComplete);
        AMap.event.addListener(geolocation, "error", onError);
        function onComplete(data) {
          // data是具体的定位信息
          // alert(data.position.lng,data.position.lat)
          Toast(data.formattedAddress);
          try {
            self.jd = data.position.lng;
            self.wd = data.position.lat;
            self.address = data.formattedAddress;
          } catch (error) {}
          map.panTo([data.position.lng, data.position.lat]);
          self.city = data.addressComponent.city;
        }
        function onError(data) {
          // 定位出错
          Toast("定位出错")
          console.log("定位失败错误：", data);
          // 调用IP定位
          self.getLngLatLocation();
        }
      });
    },
    // 通过IP获取当前位置
    getLngLatLocation() {
      const self = this;
      AMap.plugin("AMap.CitySearch", function () {
        var citySearch = new AMap.CitySearch();
        citySearch.getLocalCity(function (status, result) {
          if (status === "complete" && result.info === "OK") {
            // 查询成功，result即为当前所在城市信息
            console.log("通过ip获取当前城市：", result);
            // 逆向地理编码
            AMap.plugin("AMap.Geocoder", function () {
              var geocoder = new AMap.Geocoder({
                // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
                city: result.adcode,
              });
              var lnglat = result.rectangle.split(";")[0].split(",");
              geocoder.getAddress(lnglat, function (status, data) {
                if (status === "complete" && data.info === "OK") {
                  console.log(data)
                }
              });
            });
          }
        });
      });
    },
    reposition() {
      this.getLocation();
    },
    confirmclockin() {
      let _that = this;
      let param = {
        applyid: this.userInfo.ApplyId,
        jd: this.jd,
        wd: this.wd,
        address: this.address,
      };
	  if(this.jd == "" || this.wd == "" || this.address == ""){
		  MessageBox("提示","获取位置信息异常，请刷新重试");
		  return false;
	  }
      StuKaoqing(param).then((res) => {
        Toast("打卡成功");
        _that.isShow = false;
      });
    },
    kqInfo() {
      let _that = this;
      let param = {
        applyid: _that.userInfo.ApplyId,
        xsid: _that.userInfo.XsId,
      };
      KqInfo(param).then((res) => {
        for (let i in res) {
          if (res[i].KqDate == this.nowDate && res[i].KqState == "1") {
            _that.isShow = false;
          }
        }
      });
    },
    formatDate() {
      let date = new Date(); 
      let Y = date.getFullYear() + "-";
      let M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      let D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "";
      this.nowDate = `${Y}${M}${D}`;
    },
  },
};
</script>

<style>
.btnSI {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 120px;
  text-align: center;
}
.Repositioning {
  margin-right: 20px;
}
</style>