import {toGet,toPost} from './request'

//学生登录
export function StudentLogin(data){
    return toPost("/StudentLogin",data)
}

//学生签到信息
export function KqInfo(data){
    return toPost("/KqInfo",data)
}

//学生签到

export function StuKaoqing(data){
    return toPost("/StuKaoqing",data)
}